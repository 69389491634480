<template>
  <div class="report" v-loading="loading">

    <section class="section">
      <div class="title">
        <div class="left">作业数据统计</div>
      </div>
      <div class="statistics">
        <div class="statistics-item">
          <div class="item-title">作业总数</div>
          <div class="item-num">{{ homeworkCount }}</div>
        </div>
        <div class="statistics-item">
          <div class="item-title">平均作业率
            <el-popover
              placement="bottom"
              trigger="hover"
              :open-delay="500"
              :close-delay="500"
              :visible-arrow="false"
              popper-class="mark-circle-tips"
              transition="el-zoom-in-top">
              <slot name="content">
                班级在选定时间段内“开始”的子作业的班级作业率平均值，反映学生在作业中的参与比率。
              </slot>
              <img slot="reference" class="mark" src="../../../assets/mark-3.png" alt="?">
            </el-popover>
          </div>
          <div class="item-num">{{ operatingRate }}%</div>
        </div>
        <div class="statistics-item">
          <div class="item-title">平均完成度
            <el-popover
              placement="bottom"
              trigger="hover"
              :open-delay="500"
              :close-delay="500"
              :visible-arrow="false"
              popper-class="mark-circle-tips"
              transition="el-zoom-in-top">
              <slot name="content">
                班级在选定时间段内“开始”的子作业的班级完成度平均值，反映学生在作业中的完成程度。
              </slot>
              <img slot="reference" class="mark" src="../../../assets/mark-3.png" alt="?">
            </el-popover>
          </div>
          <div class="item-num">{{ finish }}%</div>
        </div>
        <div class="statistics-item">
          <div class="item-title">平均得分率
            <el-popover
              placement="bottom"
              trigger="hover"
              :open-delay="500"
              :close-delay="500"
              :visible-arrow="false"
              popper-class="mark-circle-tips"
              transition="el-zoom-in-top">
              <slot name="content">
                班级在选定时间段内“开始”的子作业的班级得分率平均值，反映学生在作业中的得分情况。
              </slot>
              <img slot="reference" class="mark" src="../../../assets/mark-3.png" alt="?">
            </el-popover>
          </div>
          <div class="item-num">{{ scoreRate }}%</div>
        </div>
      </div>
    </section>


    <section class="section">
      <div class="title">
        <div class="left">学生完成度分布统计</div>
        <div class="right">*点击蓝色柱状图可查看对应学生名单</div>
      </div>
      <div class="content" ref="content">
        <barForReport ref="activeBar"/>
      </div>
    </section>

    <section class="section">
      <div class="title">
        <div class="left">学生得分率分布统计</div>
        <div class="right">*点击蓝色柱状图可查看对应学生名单</div>
      </div>
      <div class="content" ref="content">
        <barForReport ref="scoreBar"/>
      </div>
    </section>

    <section class="section">
      <div class="title">
        <div class="left">班级学生数据明细</div>
        <div class="right right-operation">
          <el-switch v-model="defaultSort" @change="switchChange"></el-switch>
          <div class="tips">
            默认排序
            <el-tooltip
              effect="dark"
              placement="bottom-end"
              :open-delay="500">
              <span slot="content" class="mark-tips">默认排序规则为：按照“得分率*作业数”的值大小倒序排列。同时，可以切换到其他排序规则：按照“得分率”大小倒序排列、按照“得分率”大小正序排列、按照“完成度”大小倒序排列、按照“完成度”大小正序排列。</span>
              <img class="mark" src="../../../assets/mark-3.png" alt="?">
            </el-tooltip>
          </div>
        </div>
      </div>
      <el-table ref="table" border :data="studentList" header-cell-class-name="table-header-cell"
                cell-class-name="table-cell" @sort-change="sortChange">
        <el-table-column label="综合排名" prop="rank" align="center"/>
        <el-table-column label="学生姓名" prop="name" align="center"/>
        <el-table-column label="作业数" prop="homeworkCount" align="center"/>
        <el-table-column label="完成度" :sort-orders="['descending', 'ascending', null]" prop="finish" sortable
                         sort-by="finish" align="center">
          <template slot-scope="{row}">
            <span>{{ row.finish }}%</span>
          </template>
        </el-table-column>
        <el-table-column label="得分率" :sort-orders="['descending', 'ascending', null]" prop="scoreRate" sortable
                         sort-by="scoreRate" align="center">
          <template slot-scope="{row}">
            <span>{{ row.scoreRate }}%</span>
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center">
          <template slot-scope="{row}">
            <el-button type="text" style="font-size: 15px" @click="userReport(row.studentId)">查看报告</el-button>
          </template>
        </el-table-column>
      </el-table>
    </section>

    <div class="foot">
      <el-popover
        placement="top"
        width="351"
        trigger="manual"
        popper-class="export-tips"
        v-model="detailVisible">
        <slot name="content">
          下载任务已提交，请前往
          <router-link to="/down/center">下载中心</router-link>
          查看进度
        </slot>
        <el-button slot="reference" class="btn btn-detail" @click="exportData('detail')">导出作业明细表</el-button>
      </el-popover>

      <el-popover
        placement="top"
        width="351"
        trigger="manual"
        popper-class="export-tips"
        v-model="statisticsVisible">
        <slot name="content">
          下载任务已提交，请前往
          <router-link to="/down/center">下载中心</router-link>
          查看进度
        </slot>
        <el-button slot="reference" class="btn btn-statistics" @click="exportData('statistics')">导出作业统计表</el-button>
      </el-popover>
    </div>

  </div>
</template>

<script>
import barForReport from './barForReport'

export default {
  name: 'Report',
  components: {
    barForReport
  },
  props: ['classId', 'dateType', 'startTime', 'endTime', 'isPrevent'],
  data() {
    return {
      statisticsVisible: false,
      detailVisible: false,
      loading: false,
      defaultSort: true,
      className: '',
      homeworkCount: 0,
      finish: 0,
      scoreRate: 0,
      operatingRate: 0,
      barWidth: '',
      monitorList: [],
      studentList: [],
    }
  },
  watch: {
    statisticsVisible(val) {
      if (val) {
        setTimeout(() => {
          this.statisticsVisible = false
          this.detailVisible = false
        }, 2000)
      }
    },
    detailVisible(val) {
      if (val) {
        setTimeout(() => {
          this.statisticsVisible = false
          this.detailVisible = false
        }, 2000)
      }
    },
    classId() {
      if (!this.dateType && (!this.startTime || !this.endTime)) return
      if (this.isPrevent) return
      this.resetView()
    },
    dateType(val) {
      if (!val) return
      if (this.isPrevent) return
      this.resetView()
    },
    endTime(val) {
      if (!this.startTime || !val) return
      const startTime = new Date(this.startTime)
      const endTime = new Date(this.endTime)
      if (startTime.getTime() > endTime.getTime()) {
        return
      }
      if (this.isPrevent) return
      this.resetView()
    }
  },
  methods: {
    resetView() {
      this.monitorList = []
      this.studentList = []
      this.getDataView()
    },
    getDataView() {
      const {dateType} = this
      if (!this.classId) return
      const dataParam = {
        classId: this.classId,
        startTime: this.startTime,
        endTime: this.endTime,
        dateType
      }
      this.loading = true
      this.$axios({
        method: 'post',
        url: this.$urls.getDataView,
        headers: {
          'Content-Type': 'application/json'
        },
        data: dataParam
      })
      .then((response) => {
        this.loading = false
        if (response?.state === '11') {
          this.defaultSort = true
          const {data} = response
          this.homeworkCount = data.homeworkCount || 0
          this.scoreRate = data.scoreRate
          this.operatingRate = data.operatingRate
          this.finish = data.finish
          this.className = data.className
          const {finishGroup, scoringGroup} = data

          const finishGroupObj = {
            name: '完成度',
            xNameList: [],
            xValueList: [],
            studentList: [],
            studentSumList: []
          }
          if (finishGroup?.length) {
            for (let i = 0, len = finishGroup.length; i < len; i += 1) {
              finishGroupObj.xNameList.push(finishGroup[i]?.columns)
              finishGroupObj.xValueList.push(finishGroup[i]?.proportion || 0)
              finishGroupObj.studentList.push(finishGroup[i]?.studentList || 0)
              finishGroupObj.studentSumList.push(finishGroup[i]?.studentSum )
            }
          }
          this.finishGroupObj = finishGroupObj
          this.initBarCharts(this.$refs.activeBar, finishGroupObj)

          const scoringGroupObj = {
            name: '得分率',
            xNameList: [],
            xValueList: [],
            studentList: [],
            studentSumList: []
          }
          if (scoringGroup?.length) {
            for (let i = 0, len = scoringGroup.length; i < len; i += 1) {
              scoringGroupObj.xNameList.push(scoringGroup[i]?.columns)
              scoringGroupObj.xValueList.push(scoringGroup[i]?.proportion || 0)
              scoringGroupObj.studentList.push(scoringGroup[i]?.studentList || 0)
              scoringGroupObj.studentSumList.push(scoringGroup[i]?.studentSum)
            }
          }
          this.scoringGroupObj = scoringGroupObj
          this.initBarCharts(this.$refs.scoreBar, scoringGroupObj)

          this.sortData(data.studentList)
        }
      })
      .catch(() => {
        this.loading = false
      })
    },
    sortChange() {
      this.defaultSort = false
    },
    switchChange(flag) {
      if (flag) {
        this.$refs.table.clearSort()
        this.sortData(this.studentList)
      }
    },
    sortData(list) {
      this.studentList = list.sort((a, b) => {
        return  a.rank - b.rank
      })
    },
    initBarCharts(target, data) {
      const {offsetWidth} = this.$refs.content
      const barWidth = offsetWidth - 30
      const echarts = require('echarts')
      const option = {
        tooltip: {
          backgroundColor: 'rgba(0, 0, 0, 0.8)',
          textStyle: {
            color: 'white'
          },
          formatter: function (params) {
            const {dataIndex} = params
            return `<p>${data?.name}：${params?.name}</p><p>人数：${data?.studentSumList[dataIndex]}人</p><p>${data?.studentList[dataIndex].join()}</p>`
          },
          extraCssText: `max-width:600px;white-space:pre-wrap`,
          confine: true
        },
        title: {
          show: true,
          subtext: '人数占比',
          subtextStyle: {
            color: '#999',
            fontSize: 13,
            fontStyle: 'normal',
            fontWeight: 'normal'
          },
          left: '55',
          top: '15',
        },
        xAxis: {
          axisLine: {show: false},
          axisTick: {show: false},
          axisLabel: {
            interval: 0,
            textStyle: {
              fontSize: '12',
              color: '#333'
            }
          },
          show: true,
          emphasis: {
            color: '#fff'
          },
          name: data.name,
          nameTextStyle: {
            verticalAlign: "top",
            lineHeight: 20
          },
          data: data?.xNameList
        },
        series: [{
          name: '人数',
          type: 'bar',
          data: data?.xValueList,
          barWidth: 30,
          itemStyle: {
            normal: {
              color: '#C0E0FB',
              label: {
                show: true,
                formatter: '{c}%',
                position: 'top',
                textStyle: {
                  color: '#999999',
                  fontSize: 13
                }
              }
            }
          },
          emphasis: {
            itemStyle: {
              color: new echarts.graphic.LinearGradient(
                0, 0, 0, 1,
                [
                  {offset: 0, color: '#6DBBFC'}, // 柱图高亮渐变色
                  {offset: 1, color: '#309AF2'} // 柱图高亮渐变色
                ]
              )
            }
          }
        }]
      }
      target.initCharts({
        dataParam: option,
        width: barWidth
      })
    },
    exportData(type) {
      const {dateType} = this
      if (!this.classId) return
      const dataParam = {
        classId: this.classId,
        startTime: this.startTime,
        endTime: this.endTime,
        dateType
      }
      this.$axios({
        method: 'post',
        url: this.$urls[type === 'detail' ? 'exportReportDetail' : 'exportReportStatistics'],
        headers: {
          'Content-Type': 'application/json'
        },
        data: dataParam
      })
      .then((response) => {
        if (response?.state === '11') {
          this[`${type}Visible`] = true
        }
      })
    },
    userReport(id) {
      const {dateType} = this
      const dataParam = {
        name: '查看个人报告',
        startTime: this.startTime,
        endTime: this.endTime,
        classId: this.classId,
        id,
        dateType
      }
      this.$router.push({
        name: 'userReport',
        query: dataParam
      })
    }
  }
}
</script>

<style scoped lang="scss">

.report {
  background-color: #FFFFFF;
  padding: 0 28px;
}

.mark-tips {
  display: block;
  width: 328px;
  margin: 0;
  font-size: 14px;
  font-weight: 400;
  color: #D8D8D8;
  line-height: 20px;
}



.section {
  background-color: #fff;
  margin-bottom: 20px;
  overflow: hidden;

  .title {
    background: #F6F6F6;
    border: 1px solid #E5E5E5;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .left {
      font-size: 17px;
      font-weight: bold;
      color: #333333;
      line-height: 24px;
      padding: 15px 0 15px 28px;
    }

    .right {
      padding-right: 28px;
      font-size: 15px;
      font-weight: 400;
      color: #999999;
      line-height: 21px;
    }

    .right-operation {
      display: flex;
      align-items: center;

      .tips {
        display: flex;
        align-items: center;
        margin-left: 15px;

        .mark {
          margin-left: 5px;
        }
      }
    }
  }

  .el-table {

    ::v-deep .table-header-cell {
      font-size: 15px;
      font-weight: bold;
      color: #333333;
      line-height: 21px;
      height: 54px;
      background: #F6F6F6;

      &:first-child {
        border-left: 1px solid #EBEEF5;
      }
    }

    ::v-deep .table-cell {
      .cell {
        font-size: 15px;
        font-weight: 400;
        color: #333333;
        line-height: 21px;
      }
    }

    ::v-deep .el-table__row td:first-child {
      border-left: 1px solid #EBEEF5;
    }

    ::v-deep .el-table__body tr:hover > td {
      background-color: #F6F6F6 !important;
    }

    ::v-deep th.ascending {
      .sort-caret.ascending {
        border-bottom-color: #333333;
      }
    }

    ::v-deep th.descending {
      .sort-caret.descending {
        border-top-color: #333333;
      }
    }
  }


  .statistics {
    display: flex;
    justify-content: space-around;
    align-items: center;


    .statistics-item {
      border: 1px solid #E5E5E5;
      border-top-color: transparent;
      height: 120px;
      width: 100%;
      text-align: center;

      &:nth-child(1),
      &:nth-child(2),
      &:nth-child(3), {
        border-right-color: transparent;
      }

      .item-title {
        font-size: 17px;
        font-weight: 400;
        color: #999999;
        padding-top: 21px;
        display: flex;
        justify-content: center;
        align-items: center;

        .mark {
          margin-left: 10px;
        }
      }

      .item-num {
        padding-top: 12px;
        font-size: 30px;
        font-weight: bold;
        color: #333333;
      }
    }
  }

}

.foot {
  text-align: center;
  padding-bottom: 20px;

  .btn {
    width: 200px;
    height: 46px;
    background: #FFFFFF;
    border-radius: 4px;
    border: 1px solid #309AF2;
  }

  .btn-detail {
    font-size: 15px;
    font-weight: 400;
    color: #309AF2;
    line-height: 21px;

    &:hover {
      background: #F6F6F6;
    }
  }

  .btn-statistics {
    background: linear-gradient(90deg, #6DBBFC 0%, #309AF2 100%);
    font-size: 15px;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 21px;
    margin-left: 28px;

    &:hover {
      background: #309AF2;
    }
  }

}


</style>
